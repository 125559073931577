import React from "react";
import Card, { CardInput } from "components/Card";
import Section from "pages/Home/components/Section";

export type ComplexSectionParams = {
  title: string;
  body: string;
  cards: CardInput[];
  customClass?: string;
};

export default function ComplexSection(props: ComplexSectionParams) {
  return (
    <Section customClass={props.customClass}>
      <h1 className="mb-[18px] text-[23px] font-semibold">{props.title}</h1>
      <p className="mb-[32px] text-[15px]">{props.body}</p>
      <div className="flex sm:flex-wrap gap-[30px] justify-evenly">
        {props.cards.map((card) => (
          <Card title={card.title} body={card.body} customClass="max-w-[352px]">
            {card.children}
          </Card>
        ))}
      </div>
    </Section>
  );
}