export default function NavBar() {
    return <>
        <div className="h-[72px] w-full p-[26px] flex justify-between">
            <div>
                <p className="text-[19px]">Silverstream</p>
            </div>
            <div className="flex justify-between w-[240px]">
                <a className="text-[15px] no-underline">About Us</a>
                <a className="text-[15px] no-underline">Services</a>
                <a className="text-[15px] no-underline">Contact</a>
            </div>
        </div>
    </>
}