import Button from "components/Button";
import TextAreaInput from "components/TextAreaInput";
import TextInput from "components/TextInput";
import { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

export type ContactUsFormParams = {
  onSubmitHandler: (data: {
    name: string;
    email: string;
    message: string;
  }) => Promise<boolean>;
};

export default function ContactUsForm(props: ContactUsFormParams) {
  const ContactUsSchema = Yup.object().shape({
    name: Yup.string().required("Please input a valid name"),
    email: Yup.string()
      .email("Please input a valid email")
      .required("Email is required"),
    message: Yup.string().required("Please type in a message"),
  });

  return (
    <>
      <h1 className="mb-[18px] text-[23px] font-semibold">Contact Us</h1>
      <p className="mb-[32px] text-[15px]">
        Have questions or need further information? Reach out to us through the
        form below
      </p>
      <div className="flex gap-[30px]">
        <div className="w-full max-w-[640px]">
          <Formik
            initialValues={{ name: "", email: "", message: "" }}
            validationSchema={ContactUsSchema}
            onSubmit={async (values, { setSubmitting, setValues, setTouched }) => {
              const submitted = await props.onSubmitHandler(values)
              setSubmitting(false);

              if (submitted) {
                setValues({ name: "", email: "", message: "" })
                setTouched({ name: false, email: false, message: false })
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  customClass="mb-[23px]"
                  placeholder="Your Name"
                  error={!!errors.name && !!touched.name ? errors.name : ""}
                />
                <TextInput
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  customClass="mb-[23px]"
                  placeholder="Your Email"
                  error={!!errors.email && !!touched.email ? errors.email : ""}
                />
                <TextAreaInput
                  name="message"
                  placeholder="Your Message"
                  customClass=" mb-[28px]"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  rows={6}
                  maxLength={300}
                  error={
                    !!errors.message && !!touched.message ? errors.message : ""
                  }
                />
                {isSubmitting ? (
                  <Button
                    type="submit"
                    text="Submitting..."
                    disabled={true}
                    customClass="max-w-[150px]"
                    onClickHandler={() => {}}
                  />
                ) : (
                  <Button
                    type="submit"
                    text="Send Message"
                    customClass="max-w-[150px]"
                    onClickHandler={() => {}}
                  />
                )}
              </form>
            )}
          </Formik>
        </div>
        <div className="lg:bg-home-map-img lg:w-[380px] lg:h-[285px]"></div>
      </div>
    </>
  );
}
