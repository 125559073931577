import React from "react";

export type CardInput = {
  title: string;
  body: string;
  customClass?: string;
  children: React.JSX.Element;
};

export default function Card(props: CardInput) {
  return (
    <>
      <div
        className={`w-full h-[352px] bg-white shadow-md rounded-[28px] p-[23px] ${props.customClass}`}
      >
        <div className="w-full overflow-hidden h-[190px] py-[5px]">
          {props.children}
        </div>
        <div className="flex flex-col items-center text-center">
          <h1 className="text-[19px] font-bold mb-[11px]">
            {props.title}
          </h1>
          <p className="text-[15px]">{props.body}</p>
        </div>
      </div>
    </>
  );
}
