type NativeButtonParams = React.ButtonHTMLAttributes<HTMLButtonElement>

export type ButtonInput = {
  text: string;
  type?: NativeButtonParams['type'];
  disabled?: NativeButtonParams['disabled'];
  onClickHandler: () => void;
  customClass?: string;
};

export default function Button(props: ButtonInput) {
  return (
    <>
      <button
        type={props.type || "button"}
        disabled={props.disabled || false}
        className={`w-full py-[17px] rounded-[28px] bg-primary text-white text-[15px] ${
          props.customClass || ""
        }`}
      >
        {props.text}
      </button>
    </>
  );
}
