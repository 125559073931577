type NativeInputParams = React.InputHTMLAttributes<HTMLInputElement>

export type TextInputParams = {
  customClass?: string;
  error?: string
} & NativeInputParams;

export default function TextInput(props: TextInputParams) {
  const { customClass, error, ...restProps } = props

  return (
    <div className={`w-full ${customClass}`}>
      <input
        className={`w-full rounded-[28px] border-[3px] border-input-stroke p-[18px]`}
        {...restProps}
      />
      {!!error && <div className="text-red-600">{error}</div>}
    </div>
  );
}
