import { IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io5"

export default function Footer() {
  return (
    <>
      <div className="w-full bg-black h-[160px] px-[30px] flex flex-col justify-center">
        <div className="flex justify-between mb-[18px]">
            <h1 className="text-[23px] text-white">Silverstream</h1>
            <div className="flex justify-between max-w-[76px] gap-[19px]">
                <IoLogoFacebook color="white" size={20} />
                <IoLogoTwitter color="white" size={20} />
                <IoLogoLinkedin color="white" size={20} />
            </div>
        </div>
        <div className="mx-auto">
          <p className="text-[15px] text-white">
            © 2023 Silverstream Innovation. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
}
