import React from "react";
import LandingPage from "pages/Home/LandingPage";
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <ToastContainer />
      <LandingPage />
    </>
  );
}

export default App;
