type NativeTextAreaParams = React.TextareaHTMLAttributes<HTMLTextAreaElement>

export type TextAreaInputParams = {
  customClass?: string;
  error?: string
} & NativeTextAreaParams;

export default function TextAreaInput(props: TextAreaInputParams) {
  const { customClass, error, ...restProps } = props

  return (
    <div className={`w-full ${customClass}`}>
      <textarea
        className={`w-full rounded-[28px] border-[3px] border-input-stroke p-[18px]`}
        {...restProps}
      ></textarea>
      {!!error && <div className="text-red-600">{error}</div>}
    </div>
  );
}
