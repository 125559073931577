import { CardInput } from "components/Card";
import NavBar from "components/NavBar";
import Hero from "./components/Hero";
import Section from "./components/Section";
import ComplexSection from "./components/ComplexSection";
import ContactUsForm from "./components/ContactUsForm";
import Footer from "components/Footer";
import { ToastContainer, toast } from "react-toastify";

const about_us_cards: CardInput[] = [
  {
    children: (
      <div className="w-full h-full rounded-[28px] bg-cover bg-home-ovision-img"></div>
    ),
    title: "Our Vision",
    body: "To be at the forefront of innovation, providing exceptional services and solutions that drive the future",
  },
  {
    children: (
      <div className="w-full h-full rounded-[28px] bg-cover bg-home-omission-img"></div>
    ),
    title: "Our Mission",
    body: "To deliver innovative and reliable solutions that exceed our clients' expectations across various sectors",
  },
  {
    children: (
      <div className="w-full h-full rounded-[28px] bg-cover bg-home-ovalues-img"></div>
    ),
    title: "Our Values",
    body: "Integrity, Excellence, Innovation and Customer Satisfaction are pillars of our success",
  },
];

const our_services_cards: CardInput[] = [
  {
    children: (
      <div className="w-[142px] h-[142px] m-auto rounded-[50%] bg-home-auto-solution-img"></div>
    ),
    title: "Automotive Solutions",
    body: "Providing comprehensive automotive solutions that drive the future of transportation",
  },
  {
    children: (
      <div className="w-[142px] h-[142px] m-auto rounded-[50%] bg-home-tech-consulting-img"></div>
    ),
    title: "Technology Consulting",
    body: "Expert technology consulting service to enhance your business operations",
  },
  {
    children: (
      <div className="w-[142px] h-[142px] m-auto rounded-[50%] bg-home-customer-support-img"></div>
    ),
    title: "Customer Service",
    body: "Dedicated customer support to ensure your satisfaction and success",
  },
];

export default function LandingPage() {
  const submitContactUsFormHandler = async (data: {
    email: string;
    name: string;
    message: string;
  }) => {
    console.log("Data submitted:", JSON.stringify(data));

    // Make api call and return true if success and false if failed
    const result = await new Promise<boolean>((resolve) =>
      setTimeout(() => {
        resolve(true);
      }, 1500)
    );

    result
      ? toast.success("Request submitted", {
        hideProgressBar: true
      })
      : toast.error("Couldn't submit your request", {
        hideProgressBar: true
      });

    return result;
  };

  return (
    <>
      <NavBar />
      <Section customClass="bg-home-hero-img bg-cover flex flex-col justify-center items-center">
        <Hero
          title="Welcome to Silverstream"
          subtitle="Leading the future with innovative solutions. Your trusted partner in
          multiple industries."
        />
      </Section>
      <ComplexSection
        title="About Us"
        body="Silverstream Innovation is a parent company overseeing a range of businesses including our renowned auto dealing company. We are dedicated to driving progress and innovation in every industry we engage in"
        cards={about_us_cards}
        customClass="py-[62px] px-[30px]"
      />
      <ComplexSection
        title="Our Services"
        body="We offer a wide range of services to meet the diverse needs of our clients. From automotive solutions of technology consulting, we are here to help you"
        cards={our_services_cards}
        customClass="py-[62px] px-[30px] bg-purple-light"
      />
      <Section customClass="py-[62px] px-[30px]">
        <ContactUsForm onSubmitHandler={submitContactUsFormHandler} />
      </Section>
      <Footer />
    </>
  );
}
