export type SectionParams = {
  children: React.JSX.Element | React.JSX.Element[];
  customClass?: string;
};

export default function Section(props: SectionParams) {
  return (
    <>
      <div className={`w-full min-h-[570px] ${props.customClass}`}>
        {props.children}
      </div>
    </>
  );
}
