import Button from "components/Button";

export type HeroParams = {
  title: string;
  subtitle: string;
}

export default function Hero(props: HeroParams) {
  return (
    <>
      <div className="w-full max-w-[850px] h-[570px] px-[50px] bg-transparent flex flex-col justify-center items-center">
        <h1 className="text-[38px] text-white mb-[21px]">{props.title}</h1>
        <p className="text-[19px] text-white mb-[32px]">{props.subtitle}</p>
        <Button
          text="Learn More"
          customClass="max-w-[150px]"
          onClickHandler={() => {}}
        />
      </div>
    </>
  );
}
